<style lang="scss" scoped>
#help-section {
  background-color: #E8E7E6;
}
</style>

<template>
  <section id="help-section" class="black--text">
    <v-container class="fill-height">
      <v-row>
        <v-col class="text-center">
          <h3 class="text-h5 text-lg-h4">Como posso te ajudar</h3>
        </v-col>
      </v-row>
      <v-row class="pt-10">
        <v-col v-for="(item, idx) in items" :key="idx">
          <help-item :item="item" />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import StartImg from '@/assets/images/home/help/start.png';
import MidImg from '@/assets/images/home/help/mid.png';
import FinishImg from '@/assets/images/home/help/finish.png';

import HelpItem from './HelpItem';

export default {
  components: {
    HelpItem,
  },
  data: () => ({
    items: [
      {
        image: StartImg,
        title: 'INÍCIO',
        text: 'Planejamos juntos o caminho que será seguido.',
      },
      {
        image: MidImg,
        title: 'MEIO',
        text: 'Te acompanho durante todo o percurso.',
      },
      {
        image: FinishImg,
        title: 'ESTABILIDADE',
        text: 'Te levo ao lugar certo e te deixo seguro lá.',
      },
    ],
  }),
}
</script>