import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: true,
    icons: {
      iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    themes: {
      dark: {
        primary: '#FFFFFF',
        secondary: '#F9FF00',
        dark: '#121212',
        black: '#1D1D1B',
        info: '#BE345E',
        error: '#EC3C3C',
      },
    },
  },
})
