<style lang="scss" scoped>
.first-col-content {
  max-width: 250px;
}

.mobile {
  .first-col-content {
    max-width: 100%;
  }
}
</style>

<template>
  <div>
    <section id="expectations-section-1" class="expectation-section black white--text">
      <v-container class="fill-height">
        <v-row>
          <v-col class="d-flex align-center justify-center" cols="12" md="4">
            <div class="first-col-content text-h4 text-md-h3">
              Eu <strong>quero que</strong> você:
            </div>
          </v-col>
          <v-col class="text-left">
            <div class="mb-4">
              Encontre resultados que não se perderão com o tempo;
            </div>
            <div class="mb-4">
              Descubra soluções eficientes e jamais milagrosas;
            </div>
            <div>
              Entenda sobre os sinais do seu corpo a partir de respostas e
              estratégias respaldadas cientificamente.
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section id="expectations-section-2" class="expectation-section white black--text">
      <v-container class="fill-height">
        <v-row>
          <v-col class="d-flex align-center justify-center"  cols="12" md="4">
            <div class="first-col-content text-h4 text-md-h3">
              <strong>Não quero</strong>
              que você:
            </div>
          </v-col>
          <v-col class="text-left">
            <div class="mb-4">
              Espere mudar o seu corpo <strong>em um mês</strong> (se você fez tudo errado por mais de um mês);
            </div>
            <div class="mb-4">
              Me peça ou me dê estimativas como <strong>"quero perder 10kg em 20 dias"</strong>.
              Apesar da nutrição ser uma ciência exata, cada organismo se comporta de
              uma maneira diferente.
            </div>
            <div>
              Sinta que precisa se <strong>desprender dos alimentos que gosta</strong> para chegar ao objetivo
              que deseja. Você não precisa.
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
export default {
  data: () => ({
  }),
}
</script>
