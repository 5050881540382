<style lang="scss" scoped>
.title {
  text-align: center !important;
  word-break: normal !important;
}
</style>

<template>
  <v-card
    rounded="xl"
    class="mx-auto my-12 black--text mx-6"
    max-width="274"
    color="#FAFAFA"
  >
    <v-img
      :src="item.image"
      class="black--text align-center justify-center"
      height="125px"
    >
      <div class="title text-h6 px-8">
        {{ item.title }}
      </div>
    </v-img>

    <v-card-text
      class="black--text py-6 pt-md-8 pb-md-10 px-6 d-flex flex-column"
      :style="`height: ${$vuetify.breakpoint.mdAndDown ? 330 : 475}px;`"
    >
      <div class="text-justify" v-html="item.text" />
      <v-spacer />
      <div>
        <v-divider color="#D9D9D9" class="mt-3 mb-1" />
        <div class="d-flex" style="font-size: 0.75rem;">
          <div class="text-uppercase">
            DURAÇÃO
          </div>
          <v-spacer />
          <div>
            {{ item.weeks }}
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>
