<template>
  <v-btn
    :block="block"
    :color="dark ? 'black' : 'secondary'"
    :text-color="dark ? 'white' : 'black'"
    :large="large"
    href="https://wa.me/5584991274717?text=Oie%20me%20conta%20mais%20sobre%20a%20consultoria"
    target="_blank"
  >
    {{ text }}
  </v-btn>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Comece agora',
    },
    dark: Boolean,
    block: Boolean,
    large: Boolean,
  },
}
</script>
