import Vue from 'vue';
import VueRouter from 'vue-router';

import Index from '@/views/Index';
import About from '@/views/About';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
  },
  {
    path: '/sobre',
    name: 'About',
    component: About,
  },
  /* ------------------- Redirect Handlers ------------------- */
  {
    path: '*',
    redirect: { name: 'Index' },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;