<style scoped>
.small-footer {
  border-top: 1px solid rgba(120, 130, 140, 0.13) !important;
  font-size: 18px !important;
  opacity: 0.5;
}
</style>

<template>
  <v-footer color="black" padless class="small-footer">
    <v-container>
      <v-divider />
      <v-card-text class="d-flex my-2">
        <v-spacer />
        <div>
          &copy; PATIPSS GROUP, {{ new Date().getFullYear() }}
        </div>
      </v-card-text>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'CoreFooter',
}
</script>
