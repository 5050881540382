<style lang="scss" scoped>
.help-item {
  max-width: 230px;
  margin: 0 auto;
}

</style>

<template>
  <div class="help-item text-center">
    <div>
      <v-img
        contain
        :src="item.image"
        :height="35"
      />
    </div>
    <div class="mt-4 text-button text-uppercase">
      {{ item.title }}
    </div>
    <div class="text-caption">
      {{ item.text }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>