<style lang="scss" scoped>
.title {
  text-align: center !important;
  word-break: normal !important;
  flex-grow: 1;
}
</style>

<template>
  <v-card
    light
    rounded="xl"
    class="my-12 black--text mx-6"
    max-width="300"
  >
    <v-card-title class="text-subtitle-2 pb-2">
      {{ item.title }}:
    </v-card-title>
    <v-card-text class="pb-2">
      &ldquo;{{ item.text }}&rdquo;
      <div class="text-right">
        <small>{{ item.time}} <v-icon x-small>mdi-check-all</v-icon></small>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>