<style lang="scss" scoped>
#text-section {
  overflow: hidden;
}

@media (min-width: 1264px) {
  .intro {
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
}

.mobile {
  .timeline {
    margin-top: 2rem;

    &::before {
      left: calc(13px - 1px) !important;
    }

    ::v-deep {
      .v-timeline-item__divider {
        min-width: 44px !important;
        justify-content: start !important;
      }

      .v-timeline-item__body {
        max-width: calc(100% - 44px);
      }
    }
  }
}
</style>

<template>
  <section id="text-section" class="white--text p-relative">
    <v-container class="text-container">
      <v-row class="intro">
        <v-col cols="12" md="6">
          <h1 class="intro-title">
            Meu nome é <strong>Patrícia</strong>,
            mas pode ser que você
            me conheça como <strong>Patipss</strong>.
          </h1>
        </v-col>
      </v-row>
      <v-timeline
        class="timeline"
        :dense="$vuetify.breakpoint.smAndDown"
        :reverse="!$vuetify.breakpoint.smAndDown"
      >
        <v-timeline-item
          v-for="(item, idx) in items"
          :key="idx"
          small
          color="secondary"
        >
          <div v-html="item.html" />
        </v-timeline-item>
      </v-timeline>
    </v-container>
  </section>
</template>

<script>


export default {
  data: () => ({
    items: [
      {
        html: `
          <p>
            Em ambas as personalidades você encontrará uma pessoa incisiva e determinada a
            defender o que acredita. A sorte de quem é acompanhado por mim, é que eu acredito
            MUITO na ciência.
          </p>
          <p>
            Eu sempre costumo dizer que a nutrição é uma ciência exata. Se você consegue aplicar
            a teoria é capaz de enxergá-la bem na frente dos seus olhos. Eu particularmente
            aprecio, dia após dia, o que uma boa distribuição de nutrientes é capaz de fazer
            no corpo de um ser humano.
          </p>
        `,
      },
      {
        html: `
          <p>
            <strong>Patrícia Barbosa</strong> é uma nutricionista que preza muito pela metodologia
            técnica. Quando você estiver insatisfeito com alguma parte do processo, ela imediatamente
            procura pelo que pode ser realocado para que a sua execução venha a melhorar.
          </p>
          <p>
            <strong>Patipss</strong> certamente irá abrir um espaço sem limite de caracteres para
            você reclamar de tudo que precisa - e do que não precisa, até que no meio de cada palavra
            você encontre suas próprias respostas.
          </p>
        `,
      },
      {
        html: `
          <p>
            <strong>Patrícia Barbosa</strong> fará protocolos de contenção de danos quando você precisar
            sair da rotina.
          </p>
          <p>
            <strong>Patipss</strong> provavelmente vai querer saber tudo o que você fez durante esse
            tempo e o quanto isso te fez feliz.
          </p>
        `,
      },
      {
        html: `
          <p>
            <strong>Patrícia Barbosa</strong> vai te prescrever vegetais, macronutrientes
            bem ajustados e suplementos que fazem sentido.
          </p>
          <p>
            <strong>Patipss</strong> certamente irá ouvir suas frustrações e validá-las.
          </p>
        `,
      },
      {
        html: `
          <p><strong>Patrícia Barbosa</strong> te acolhe e te faz entender como você chegou onde está.</p>
          <p><strong>Patipss</strong> te trás para a realidade e te ajuda a lidar com ela.</p>
        `,
      },
    ],
  }),
};
</script>
