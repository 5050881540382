<template>
  <div>
    <v-tooltip left  v-for="(item, idx) in items" :key="idx">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          icon
          class="ma-2 mx-2"
          color="primary"
          v-bind="attrs"
          v-on="on"
          :href="item.type === 'link' ? item.link : null"
          :title="item.text"
          @click="item.type === 'button' ? open(item.link) : null"
        >
          <v-icon
            v-text="item.icon"
          />
        </v-btn>
      </template>
      <span v-if="item.text">{{ item.text }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        text: 'Instagram',
        icon: 'mdi-instagram',
        type: 'button',
        link: 'https://www.instagram.com/patipss/'
      },
      // {
      //   text: 'WhatsApp',
      //   type: 'button',
      //   link: 'https://api.whatsapp.com/send?phone=16474619602&text=Ol%C3%A1.%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20consultoria%20nutricional%20online',
      //   icon: 'mdi-whatsapp',
      // },
      // {
      //   text: 'Email',
      //   type: 'link',
      //   link: 'mailto:patriciabarbosakm@gmail.com',
      //   icon: 'mdi-email',
      // },
    ],
  }),
  methods: {
    open(link) {
      window.open(link, '_blank');
    },
  },
}
</script>