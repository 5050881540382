<template>
  <div>
    <welcome-section />
    <help-section />
    <testimonials-section />
    <expectation-section />
    <plans-section />
    <faq-section />
    <callout-section />
  </div>
</template>

<script>
import WelcomeSection from '@/components/home/Welcome';
import HelpSection from '@/components/home/help/Help';
import TestimonialsSection from '@/components/home/testimonials/Testimonials';
import ExpectationSection from '@/components/home/Expectations';
import PlansSection from '@/components/home/plans/Plans';
import FaqSection from '@/components/home/FAQ';
import CalloutSection from '@/components/home/Callout';

export default {
  components: {
    WelcomeSection,
    HelpSection,
    TestimonialsSection,
    ExpectationSection,
    PlansSection,
    FaqSection,
    CalloutSection,
  },
}
</script>