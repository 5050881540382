<style lang="scss" scoped>
.nav-logo-container {
  &.expand {
    flex-grow: 1;
  }

  &:not(.expand) {
    .logo::v-deep .v-image__image {
      background-position: left center !important;
    }
  }
}
.nav-content {
  display: flex;
  flex-grow: 1;
  align-items: center;
}
</style>

<template>
  <v-app-bar
    app
    shrink-on-scroll
    elevate-on-scroll
    ref="appBar"
    :min-height="mobile ? 56 : 65"
    :class="{ 'pt-1': !mobile }"
    :color="color"
  >
    <v-container class="py-0 fill-height">
      <div class="nav-logo-container" :class="{ 'expand' : mobile }">
        <v-img
          :src="require('@/assets/images/logo/logo-complete.png')"
          contain
          class="logo clickable"
          :height="mobile ? 25 : 35"
          @click="goHome"
        />
      </div>

      <div v-if="!mobile" class="nav-content">
        <v-spacer />
        <v-btn
          text
          v-for="(link, idx) in links"
          :key="idx" :href="link.href"
          class="mr-2"
        >
          {{ link.text }}
        </v-btn>
        <buy-course-btn />
        <contact-btn />
        <v-spacer />
        <social-media-btns />
      </div>
      <v-app-bar-nav-icon
        v-else
        @click="$emit('input', !value)"
      />

    </v-container>
  </v-app-bar>
</template>

<script>
import BuyCourseBtn from './buttons/BuyCourse';
import ContactBtn from './buttons/Contact';
import SocialMediaBtns from './buttons/SocialMedia';

export default {
  name: 'CoreAppBar',
  components: {
    BuyCourseBtn,
    ContactBtn,
    SocialMediaBtns,
  },
  props: {
    mobile: Boolean,
    value: Boolean,
  },
  data: vm => ({
    links: [
      { text: 'Sobre', href: vm.$router.resolve({ name: 'About' }).href },
      { text: 'Contato', href: 'mailto:patriciabarbosakm@gmail.com' },
    ],
    color: 'transparent',
  }),
  methods: {
    goHome() {
      if (this.$route.name === 'Index') {
        document.body.scrollIntoView({ behavior: 'smooth' });
      } else {
        this.$router.push({ name: 'Index' });
      }
    },
  },
  mounted() {
    this.$watch(
      "$refs.appBar.computedHeight",
      (new_value) => {
        if (new_value < 80) this.color = 'black';
        else this.color = 'transparent';
      }
    );
  },
};
</script>
