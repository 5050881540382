<style lang="scss" scoped>
#welcome-section {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: -128px !important;
  height: calc(100vh);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../assets/images/home/welcome/background-color.webp');
}

.text-content {
  font-weight: 200 !important;
  font-size: 4rem;
  line-height: 4.5rem;
  margin-top: 400px;

  @media (max-height: 960px) {
    font-size: 2.5rem;
    line-height: 3rem;
    margin-top: 300px;
  }

  @media (min-width: 960px) and (max-width: 1904px) {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}

.bg-layer {
  position: absolute;
  inset: 0;
  top: -128px;
  background-color: rgba(0, 0, 0, 0.2);
}

.mobile {
  .text-content {
    font-size: 2rem;
    line-height: 2rem;
    text-align: center;
    max-width: 400px;
    min-width: 360px;
    position: absolute;
    bottom: 33%;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>

<template>
  <section id="welcome-section" class="white--text">
    <div class="bg-layer" />
    <v-container class="fill-height p-relative">
      <v-row>
        <v-col offset="0" offset-md="6" offset-lg="8">
          <h1 class="text-content">
            Quando o básico é bem feito torna-se <strong>extraordinário</strong>.
          </h1>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
};
</script>
