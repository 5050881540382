<template>
  <v-app>
    <app-mobile-menu v-if="mobile" v-model="menu" />
    <app-bar :mobile="mobile" v-model="menu" />

    <v-main :class="{ 'mobile x-overflow-hidden': mobile }">
      <router-view />
    </v-main>

    <app-footer />
  </v-app>
</template>

<script>
import '@/assets/css/base.scss';
import AppBar from '@/components/base/AppBar';
import AppMobileMenu from '@/components/base/MobileMenu';
import AppFooter from '@/components/base/Footer';

export default {
  name: 'App',
  components: {
    AppBar,
    AppMobileMenu,
    AppFooter,
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    mobile: vm => vm.$vuetify.breakpoint.smAndDown,
  },
}
</script>
