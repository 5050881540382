<style scoped>
#testimonial-section {
  min-height: 100vh;
  background: rgb(244,247,99);
  background: linear-gradient(90deg, rgba(244,247,99,1) 20%, rgba(186,224,215,1) 100%);
}

.phone-case {
  position: absolute;
  left: 48%;
  top: 50%;
  transform: translateX(-50%) translateY(-52%);
  z-index: 0;
}

.text-container .text-h5 {
  font-size: 20px !important;
}

.testimonials-container {
  max-width: 850px;
}
</style>

<template>
  <section id="testimonial-section" class="black--text section-full-height">
    <v-container class="fill-height">
       <v-row v-if="$vuetify.breakpoint.mobile">
        <v-col>
          <div class="mb-6 text-h5 text-center">
            Se cada vez que eu facilitasse sua vida, sumisse um pouquinho
            de mim... <strong>Opa, cadê eu?</strong>
          </div>
          <v-carousel
            continuous
            hide-delimiter-background
            :show-arrows="false"
            height="270px"
            class="mb-10"
          >
            <v-carousel-item v-for="(item, idx) in items" :key="idx">
              <testimonial-item :item="item" class="mx-auto" />
            </v-carousel-item>
          </v-carousel>
          <div class="text-center">
            <contact-button dark large text="Quero experimentar" />
          </div>
        </v-col>
      </v-row>
      <v-row v-else class="pt-10">
        <v-col class="p-relative" cols="8">
          <v-img
            :src="require('@/assets/images/home/testimonials/cellphone-case.png')"
            contain
            class="phone-case"
            :height="800"
          />
          <div class="d-flex flex-column mx-auto testimonials-container">
            <testimonial-item
              v-for="(item, idx) in items"
              :key="idx"
              :item="item"
              :class="{ 'align-self-end': idx % 2 === 1 }"
            />
          </div>
        </v-col>
        <v-col class="d-flex align-center justify-center">
          <div class="text-container mx-6">
            <div class="mb-8 text-h5">
              Se cada vez que eu facilitasse sua vida, sumisse um pouquinho
              de mim... <strong>Opa, cadê eu?</strong>
            </div>
            <div class="text-justify mb-8">
              Todas as modalidades de acompanhamento foram pensadas considerando a
              <strong>comodidade</strong> do paciente e a <strong>efetividade</strong>
              da abordagem, dessa forma, todos os planos possuem suas próprias formas
              de acompanhamento (diário alimentar, vídeo-chamada, WhatsApp), mas via
              de regra o WhatsApp será nosso principal canal de <strong>comunicação</strong>,
              pois isso me garante que eu esteja sempre mais perto de você.
            </div>
            <contact-button dark large block text="Quero experimentar" />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import ContactButton from '@/components/base/buttons/Contact';

import TestimonialItem from './TestimonialItem';

export default {
  components: {
    ContactButton,
    TestimonialItem,
  },
  data: () => ({
    items: [
      {
        title: 'Wanderson',
        text: 'Assim que coloquei as fotos no app eu percebi a diferença',
        time: '21:02',
      },
      {
        title: 'Adriana',
        text: 'Ai eu tô tão empolgada para os próximos passos. É muito bom ver a mudança acontecendo',
        time: '09:37',
      },
      {
        title: 'Luiza',
        text: 'Como é especial ter esse acompanhamento, não tenho palavras para agradecer suas palavras sempre que precisei',
        time: '12:14',
      },
    ],
  }),
};
</script>