<style lang="scss" scoped>
.floating-img {
  position: absolute;
  left: 75%;
  transform: translateX(-50%);
  top: 4rem;
  width: 30vw;
  max-width: 441px;
}

@media (max-width: 959px) {
  .floating-img {
    left: 50%;
    top: 0;
    width: 100%;
    position: relative;
    display: block;
  }

  .content-container {
    margin-top: 0;
  }
}

@media (min-width: 1904px) {
  .floating-img {
    top: 0 !important;
  }
}
</style>

<template>
  <div>
    <v-container class="p-relative py-0">
      <img class="floating-img" src="@/assets/images/about/about.webp" />
    </v-container>
    <div class="p-relative content-container">
      <text-section />
      <luck-section />
      <callout-section />
    </div>
  </div>
</template>

<script>
import TextSection from '@/components/about/Text';
import LuckSection from '@/components/about/Luck';
import CalloutSection from '@/components/home/Callout';

export default {
  components: {
    TextSection,
    LuckSection,
    CalloutSection,
  },
}
</script>
