<style lang="scss" scoped>
::v-deep {
  .v-expansion-panel::before {
    box-shadow: none !important;
  }
}

#faq-section {
  min-height: calc(50vh - 65px);
}

.semi-circle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;

  ::v-deep .v-image__image {
    background-position: bottom center !important;
  }
}

.faq {
  margin-top: 200px;
}

.mobile {
  .faq {
    margin-top: 200px;
  }

  #faq-section {
    min-height: calc(100vh - 65px);
  }
}
</style>

<template>
  <section
    id="faq-section"
    class="white black--text p-relative"
  >
    <v-img
      class="semi-circle"
      :src="require('@/assets/images/home/faq/semi-circle.png')"
      :height="$vuetify.breakpoint.smAndDown ? '200px' : '275px'"
    />
    <v-container class="p-relative" :class="{'fill-height': !$vuetify.breakpoint.smAndDown }">
      <v-row>
        <v-col class="text-center">
          <h3 class="title text-h5 text-lg-h4 white--text">
            Dúvidas frequentes
          </h3>
        </v-col>
      </v-row>
      <v-row class="faq">
        <v-col>
          <v-expansion-panels light accordion>
            <v-expansion-panel v-for="(item, idx) in items" :key="idx">
              <v-expansion-panel-header>
                {{ item.title }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ item.text }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        title: 'COMO FUNCIONA A AVALIAÇÃO FISICA ON-LINE?',
        text: 'Na modalidade on-line a avaliação física é feita por meio de fotos. A cada 15 dias as fotos serão avaliadas e comparadas por meio de vetores feitos por mim, com o intuito de avaliar cada medida de forma isolada. As fotos ficam armazenadas em uma pasta com senha dentro do aplicativo.',
      },
      {
        title: 'VOCÊ ATENDE PELO PLANO DE SAÚDE?',
        text: 'O atendimento por meio de convênios não está disponível, mas caso o seu plano de saúde trabalhe com reembolso, você pode solicitá-lo uma vez que ao final da consulta será gerado um recibo.',
      },
      {
        title: 'É PRECISO FAZER EXAMES LABORATORIAIS?',
        text: 'A avaliação da necessidade de exames é feita durante a primeira consulta e ao longo do acompanhamento, mas caso você tenha exames de até 3 meses antes do dia da consulta, sugiro enviar no ato de preenchimento do formulário pré-consulta.',
      },
      {
        title: 'POR QUE EU PRECISO ENVIAR FOTOS?',
        text: 'As fotos são necessárias para avaliar o progresso além do peso na balança, visto que dependendo da atividade física do indivíduo, o peso não deve ser considerado um padrão fidedigno de análise evolutiva. Caso o paciente não sinta-se confortável em ser acompanhado dessa forma, ao longo da primeira consulta conversaremos sobre outros métodos de avaliação física que ele mesmo possa conduzir.',
      },
    ],
  }),
};
</script>