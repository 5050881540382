<template>
  <v-navigation-drawer
    app
    right
    :value="value"
    @input="val => $emit('input', val)"
  >
    <v-img
      :src="require('@/assets/images/logo/logo-white.png')"
      height="35"
      class="mt-10 mb-5"
      contain
    />

    <v-list nav>
      <v-list-item :to="{ name: 'About' }">
        <v-list-item-title>
          Sobre
        </v-list-item-title>
      </v-list-item>

      <v-list-item @click="toEmail">
        <v-list-item-title>
          Contato
        </v-list-item-title>
      </v-list-item>

      <buy-course-btn as-list-item />
    </v-list>

    <template v-slot:append>
      <div class="text-center mb-6">
        <contact-btn  class="mb-6" />
        <social-media-btns />
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import BuyCourseBtn from './buttons/BuyCourse';
import ContactBtn from './buttons/Contact';
import SocialMediaBtns from './buttons/SocialMedia';

export default {
  components: {
    BuyCourseBtn,
    ContactBtn,
    SocialMediaBtns,
  },
  props: {
    value: Boolean,
  },
  methods: {
    toEmail() {
      window.open('mailto:patriciabarbosakm@gmail.com');
    },
  },
};
</script>