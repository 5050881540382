<style lang="scss" scoped>
.text {
  max-width: 600px;
  text-align: center;
}

.mobile {
  #plans-section {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}
</style>

<template>
  <section id="plans-section" class="white--text section-full-height">
    <v-container class="fill-height">
      <v-row>
        <v-col class="text-center">
          <h3 class="text-h5 text-lg-h4">
            Planos de <strong>acompanhamento</strong>
          </h3>
        </v-col>
      </v-row>
      <v-row v-if="$vuetify.breakpoint.mdAndDown" class="mt-n4">
        <v-col>
          <v-carousel
            hide-delimiter-background
            :show-arrows="false"
            height="550px"
          >
            <v-carousel-item v-for="(item, idx) in items" :key="idx">
              <plan-item :item="item" />
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
      <v-row v-else class="pt-10">
        <v-col v-for="(item, idx) in items" :key="idx">
          <plan-item :item="item" />
        </v-col>
      </v-row>
      <v-row class="pt-4 pt-lg-10">
        <v-col class="text-center">
          <contact-button large text="Quero experimentar" />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import LeftImg from '@/assets/images/home/plans/left.png';
import RightImg from '@/assets/images/home/plans/right.png';

import ContactButton from '@/components/base/buttons/Contact';

import PlanItem from './PlanItem';

export default {
  components: {
    ContactButton,
    PlanItem,
  },
  data: () => ({
    items: [
      {
        image: LeftImg,
        title: 'ONLINE',
        text: `
          <div>Na modalidade online, todo o cuidado e atenção são direcionados com tanta dedicação que faz parecer que estamos perto.</div>
          <ul class="mt-4 text-left">
            <li>1 consulta por vídeo-chamada;</li>
            <li>5 semanas de acompanhamento remoto;</li>
            <li>Acesso ao app;</li>
            <li>Avaliação física por meio de vetores.</li>
          </ul>
        `,
        weeks: 'min. 5 semanas',
      },
      {
        image: RightImg,
        title: 'PRESENCIAL',
        text: `
          <div>Se você mora em Natal - RN, tem a opção de elaborar seu plano alimentar junto comigo!</div>
          <ul class="mt-4 text-left">
            <li>Consulta completa (anamnese, avaliação física, avaliação bioquímica e comportamental);</li>
            <li>5 semanas de acompanhamento remoto;</li>
            <li>Acesso ao app;</li>
            <li>1 retorno presencial.</li>
          </ul>
        `,
        weeks: 'min. 5 semanas',
      },
    ],
  }),
};
</script>
