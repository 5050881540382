<style lang="scss" scoped>
.secondary-color {
  color: #f9ff00 !important;
}
</style>

<template>
  <v-list-item v-if="asListItem" target="_blank" :href="href" class="secondary-color">
    <v-list-item-title>
      {{ text }}
    </v-list-item-title>
  </v-list-item>
  <v-btn
    v-else
    text
    class="mr-2"
    target="_blank"
    :color="color"
    :href="href"
  >
    {{ text }}
  </v-btn>
</template>
<script>
export default {
  props: {
    asListItem: Boolean,
  },
  data: () => ({
    text: 'Guia do Mínimo',
    color: 'secondary',
    href: 'https://pay.hotmart.com/J85589325F?checkoutMode=2',
  })
}
</script>