<style lang="scss" scoped>
#callout-section {
  height: calc(50vh - 104px);
  min-height: 500px;
}
.text {
  max-width: 600px;
  text-align: center;
}

.mobile {
  #callout-section {
    height: calc(100vh - 104px - 56px);
  }
}
</style>

<template>
  <section id="callout-section" class="white--text">
    <v-container class="fill-height">
      <v-row>
        <v-col class="text-center">
          <h3 class="text-h5 text-lg-h4">E agora?</h3>
        </v-col>
      </v-row>
      <v-row class="pt-10">
        <v-col>
          <div class="text mx-auto">
            Se você chegou até aqui é porque sabe que quer alguma coisa,
            se não sabe exatamente o quê, clique no botão abaixo que eu
            te ajudo a descobrir.
          </div>
        </v-col>
      </v-row>
      <v-row class="pt-10">
        <v-col class="text-center">
          <contact-button large text="Quero saber mais" />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import ContactButton from '@/components/base/buttons/Contact';

export default {
  components: {
    ContactButton,
  },
};
</script>